import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
	name: 'stripHTML',
})
export class StripHTMLPipe implements PipeTransform {
	transform(html: string | null = ''): string {
		if (!html) return '';

		return html.replace(/<[^>]*>/g, '');
	}
}
