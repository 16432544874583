export interface PromoSlot {
	id: number;
	title: string;
	description: string;
	image: string;
	link: string;
	is_tracked?: boolean;
	university?: PromoSlotsUniversity | null;
	index?: number;
}

export interface PromoSlotsUniversity {
	id: number;
	name: string;
}

export enum PromoSlotComponentName {
	ROW = 'Promo Slot Row',
	SIDEBAR = 'Promo Slot Sidebar',
	NAV = 'Promo Slot Nav',
}

export type PromoSlotComponentNameType = `${PromoSlotComponentName}`;
